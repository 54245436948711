@import "../../styles/variables";

.breadcrumbs {
  width: 100%;
  margin-bottom: 41px;

  .title {
    background-color: $white-color;
    position: relative;
    padding-right: 16px;
    display: inline-block;
    font-size: 1.25rem;
    float: left;
    margin-top: -68px;
    margin-left: 95px;
    margin-bottom: 0;
    color: $purple;
  }

  .line {
    width: calc(100% - 120px);
    height: 2px;
    background: $secundary-color;
    z-index: -1;
    margin-top: -58px;
    float: right;
  }

  .breadcrumbs-list {
    list-style: none;
    margin-bottom: 5px;
    padding: 0;
    text-align: right;

    .breadcrumbs-list-item {
      display: inline-block;
      width: 30px;
      height: 30px;
      // background: $white-color;
      background: $main-color;
      border-radius: 40px;
      border: 2px solid $secundary-color;
      color: $secundary-color;
      margin-left: 12px;
      margin-bottom: 13px;
      // line-height: 62px;
      font-weight: 700;

      &:first-child {
        content: "";
        text-align: center;
        float: left;
        margin-left: 0;
        border-color: $main-color;
        color: $main-color;
      }
    }

    .breadcrumbs-list-item-whiteBorder {
      display: inline-block;
      width: 30px;
      height: 34px;
      //background: $white-color;
      //border-radius: 22px;
      //border: 2px solid $white-color;
      color: $secundary-color;
      margin-left: 12px;
      line-height: 30px;
      font-weight: 700;

      &:first-child {
        content: "";
        text-align: center;
        float: left;
        margin-left: 0;
        border-color: $main-color;
        color: $main-color;
      }
    }

    .breadcrumb-text {
      text-align: center;
    }

    .breadcrumb-active {
      border-color: $main-color;
    }

    .breadcrumb-done {
      background-color: $main-color;
      color: $white-color;
      text-align: center;
      border-color: $main-color;
    }

    .breadcrumb-done::before {
      font-family: 'FontAwesome';
      font-weight: 100;
      content: "\f00c";
      font-size: 30px;
    }
  }
}
