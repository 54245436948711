@import '../../styles/variables';

.conclusion{
  text-align: center;
  
  .icon-col {
    display: flex;
    align-content: center;
  }

  .icon{
    margin-top: 100px;
    color: $main-color;
    font-size: 8.2em;
  }

  .title{
    color: #222222;
    padding: 16px 0px;
  }

  .message{    

  }

  .link {
    margin-top: 100px;
    a {
      text-decoration: none;
      color: white;
    }
  }

}

