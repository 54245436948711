
/* COLOR SETTINGS */
$main-color: #672CB0;
$secundary-color: #ABABAB;
$text-color: #2E2E2E;
$white-color: #FFF;

/* FONT SETTINGS */
// $main-font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
$main-font-family: "Inter";


/* WIDTH SETTINGS */
$width-sm:  576px;
$width-md:  768px;
$width-lg:  992px;
$width-xl:  1170px;


/* AUXILIARY COLOR */

$info: rgb(0, 119, 255);
$success: rgb(50, 255, 101);
$warnning: rgb(245, 180, 82);
$danger: rgb(248, 56, 56);
$neutral: rgb(34, 34, 34);

/* GENERIC COLORS */

$dark-green: #078A34;
$purple: #672CB0;

 
 