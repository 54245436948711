@import '../../styles/variables';


.field {
	position: relative;
	margin: auto;
	width: 100%;
	vertical-align: baseline;

	.label {
		position: absolute;
		top: 0px;  //12px
		left: 0;
		font-size: 16px;
		color: #9098a9;
		font-weight: 500;
		transform-origin: 0 0;
		transition: all 0.2s ease;
		white-space: nowrap;
    transform: translateY(-26px) scale(0.75);
	}

	&.has-value .label {
		transform: translateY(-26px) scale(0.75);
	}

 .border {
		// position: absolute;
		// bottom: 1px;
		// left: 0;
		// height: 2px;
		// width: 100%;
		// background: $purple;
		// transform: scaleX(0);
		// transform-origin: 0 0;
		// transition: all 0.15s ease;
		// margin-bottom: 15px;

		.danger{
			background: $danger;
		}

		.success{
			background: $success;
		}

		.warnning{
			background: $warnning;
		}

		.info{
			background: $purple;
		}
	}

	input {
		-webkit-appearance: none;
		width: 100%;
		border: 0;
		font-family: inherit;
		padding: 12px 0 8px 0px;
		margin-top: 18px;
		height: 30px;
		font-size: 16px;
		font-weight: 500;
		border-bottom: 2px solid #c8ccd4;
		background: none;
		border-radius: 0;
		color: #223254;
		transition: all 0.15s ease;

		&:hover {
			background: rgba(34,50,84,0.03);
		}
		
		&:not(:placeholder-shown) ~ .label {
			color: #5a667f;
			transform: translateY(-26px) scale(0.75);
		}

		&:focus {
			background: none;
			outline: none;
		}
		
		&:focus ~ .label {
			color: $purple;
			//transform: translateY(-22px) scale(0.75);
		}
		
		&:focus ~ .label ~ .border {
			transform: scaleX(1);
		}
	}


	.message{
		display: block;
		width: 100%;
		padding: 8px 0px;
	}

	.icon{
		display: block;
		position: absolute;
		top: -10px;
		float: right;
		width: 38px;
		height: 38px;
		right: 0px;

		img{
			width: 100%;
		}
		
	}

	&.danger{

		input{
			border-bottom-color: $danger;
		}
		.label, .message{
			color: $danger;
		}
	}

	&.info{

		input{
			border-bottom-color: $purple;
		}
		.label, .message{
			color: $purple;
		}
	}

}





// .inp input.info{
// 	border-bottom-color: $info;
// }

// .inp input.success{
// 	border-bottom-color: $success;
// }

// .inp input.warnning{
// 	border-bottom-color: $warnning;
// }

