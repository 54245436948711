// @import "../../styles/variables";

.registration-cond-card {

  display: flex;
  justify-content: space-between;

  .label {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    color: #8E8E8E;
  }

  @keyframes background-fade {
    0% {
      background-color: #FFF;
      color: #582597;
    }

    100% {
      background-color: #582597;
      color: #FFF;
    }
  }

  .item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 304px;
    height: 324px;
    border: 8px solid #582597;
    border-radius: 20px;
    cursor: pointer;

    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    color: #672CB0;
  }

  @media screen and (max-width: 980px){
    .item-card{
      width: 220px;
      height: 260px;

      .itemCard{
        padding-bottom: 10px !important;
        font-size: 15px !important;
      }
    }
  }

  @media screen and (max-width: 750px){
    .item-card{
      width: 240px !important;
      height: 100px;
      background-size: 30%;
      background-position-y: 0.5rem !important;
      padding-top: 5px !important;
      margin-right: 1px !important;      
      border-width: 4px;
      padding: 2px;     

      .itemCard{
        padding-bottom: 5px !important;
        font-size: 12px !important;        
        :last-child{
          font-size: 10px !important;
          padding: 5px;
        }
      }

    }

    :nth-child(1), :nth-child(1).selected{
      background-size: 35%;     
    }

    :nth-child(2), :nth-child(2).selected{
      background-size: 25%;     
    }

    :nth-child(3).selected{ 
       background-size: 30%
    }
  }

  .item-card:hover,
  .item-card.selected {
    color: #FFF;
    background-color: #582597;
  }

  .item-card.selected {
    animation: background-fade 1s;
  } 
}
