@import "../../styles/variables";

.summaryData {
  section {
    .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
        padding: 12px 0;
        border-bottom: 1px solid #000000;
        margin: 30px 0;
    }

    .data {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }

        span {
            font-family: 'Inter';
            font-style: normal;           
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            font-weight: 700;
        }

        label {
            padding-right: 12px;
        }

        .checkbox-conditions, .checkbox-terms-of-use {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #672CB0;
            border-radius: 5px;
            margin-right: 13px;
            width: 22px;
            height: 22px;
            padding: 0;
            cursor: pointer;            
        }

        .checkbox-conditions.checked span::after, .checkbox-terms-of-use span::after {
            font-family: 'FontAwesome';            
            content: "\f00c";    
            color: #F8981C;    
            font-size: 14px;    
        }

        .terms-and-conditions {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;           
        }

        .link {
            text-decoration-line: underline;
            color: #0047FF;
            cursor: pointer;
        }
    }
  }
}
