@import "./\ mixins";
@import "./\_variables";

.container {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

div[class^="col"] {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.flex-space-b {
  display: flex;
  justify-content: space-between;
}

/* Desktop Media Query */
@media screen and (min-width: $width-lg) {
  .container {
    width: $width-xl;
    padding: 8px;
    margin: 0 auto;
  }

  div[class^="col"] {
    padding: 5px;
  }

  .col-5 {
    width: 5% !important;
  }

  .col-10 {
    width: 10% !important;
  }

  .col-15 {
    width: 15% !important;
  }

  .col-20 {
    width: 20% !important;
  }

  .col-25 {
    width: 25% !important;
  }

  .col-30 {
    width: 30% !important;
  }

  .col-33 {
    width: 33.33% !important;
  }

  .col-35 {
    width: 35% !important;
  }

  .col-45 {
    width: 45% !important;
  }

  .col-50 {
    width: 50% !important;
  }

  .col-60 {
    width: 60% !important;
  }

  .col-66 {
    width: 66.66% !important;
  }

  .col-70 {
    width: 70% !important;
  }

  .col-75 {
    width: 75% !important;
  }

  .col-80 {
    width: 80% !important;
  }

  .col-90 {
    width: 90% !important;
  }

  .col-95 {
    width: 95% !important;
  }

  .col-togle {
    width: 7% !important;
  }

  .col-togle-p {
    width: 93% !important;
    margin-top: 20px;
  }
}
