@import './variables';

@import './reset';
@import './grid';

*{
  font-family: $main-font-family;
}

.card-container{
  padding: 16px;

  .card{
    padding: 32px 0px;
    
    img{
     margin-right: 16px; 
    }
    .content-text{
      width: calc(100% - 75px);
      padding-left: 16px;
    }
    h3{
      margin: 0 0 12px 0;
    }

    img, .content-text{
      display: inline-block;
      margin: 0;
    }
  }
}
.ui-alert-toast{
  padding: 1em;
  background-color: $purple;
  line-height: 1.2em;
}

.p-0 {
  padding: 0 !important;
}
