@import "../../../styles/variables";

.detailCondominium {
    .units-condominium {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        
        .btn-delete-unit {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-right: 12px;
            margin-bottom: 10px;
            border: 1px solid $purple;
            border-radius: 4px;
            cursor: pointer;
        }   
    }

    .material-icons {
        color: $purple !important;    
    }    

    .btn-add-floor button {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 12px !important;  
        line-height: 17px;  
    }

    .btn-add-unit button {
        margin: 0 !important;
        padding: 5px !important;
        font-size: 12px !important;    
    }

    .btn-delete-floor {
        cursor: pointer;
    }

    .table-registration-cond table tr:last-child td:first-child {
        border-bottom-left-radius: unset !important;
    }
}