@import "../../../styles/variables";

.detailBuilding {
    .units-building {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        
        .btn-delete-unit {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-right: 12px;
            margin-bottom: 10px;
            border: 1px solid $purple;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    
    .material-icons {
        color: $purple !important;    
    }
    
    .bt-delete-floor {
        cursor: pointer;
    }
    
    .btn-add-unit button {
        margin: 0 !important;
        padding: 5px !important;
        font-size: 12px !important;    
    }
}

