@import "../../styles/variables";


.errorContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 400px;

  .erroLabel {
    text-align: center;
    max-width: 450px;    
  }

  .errorLink {
    cursor: pointer;
  }
}

.payment-methods {
  .col {
    margin-bottom: 20px;
  }

  .description {
    margin-bottom: 50px;
    div {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }
  
    span {
      font-family: 'Inter';
      font-style: normal;           
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-weight: 700;   
    }
  
    label {
      padding-right: 12px;
    }
  }

  .methods {
    display: flex;
    width: 100%;
    gap: 45px;   
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;

    .card {     
      min-width: 300px;
      background-color: #F9F3FF;
      height: 200px;
      display: flex;
      flex: 1;

      div {
        text-align: center;
        font-weight: 700;
        color: $purple;
        display: flex;
        align-items: center;
        gap: 15px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .brand-icons {
          display: flex;
          gap: 15px;
        }       

        button {
          display: flex;      
          padding: 5px 0;   
          border-radius: 25px;       
          align-items: center;
          justify-content: center;
          margin-top: 10px;             
          transition: 0.2s;  
          text-transform: none;
          border: 2px solid #582597;
          color: #582597;
          background-color: #fff;
          &:hover {
            cursor: pointer;
            background-color: #eeeded;
            opacity: 0.9;
          }
        } 
      }
    }

  }

}


