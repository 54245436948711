@import '../../styles/variables';

header{
  .logo-container {
    text-align: center;

    .logo {
      max-width: 81px;
      width: 100%;
      margin: 30px auto 40px auto;
      float: left;
    }

    .logo-parceiro {
      float: right;
    }
  }
}

@media screen and (min-width: $width-lg) {
  header {
    .logo-container {
      text-align: center;
    }
  }
}

