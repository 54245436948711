@import "../../styles/variables";

.detailCondominiumUnits {
    input, input:hover {
        border: none;
        background: none;
        outline: none;
        font-size: 20px;
    }

    input::selection, input::-moz-selection {
        background: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #EDEDED inset !important;
    }

    textarea, textarea:hover {
        border: none !important;
        background: none !important;;
        outline: none !important;;
    }

    textarea::selection, textarea::-moz-selection {
        background: none !important;;
    }

    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover, 
    textarea:-webkit-autofill:focus, 
    textarea:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #EDEDED inset !important;
        outline: none !important;;
    }

    button img {
        padding-left: 12px;
    }

    textarea {
        resize: none;
        min-height: 16px;
    }
}