@import "../../../styles/variables";

.condominiumSpec_house_streetCount {
    margin: 30px 0 -12px 0;
}

.condominiumSpec_house_unitsPerStreet {
    margin-top: 30px
}

.label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    color: #707070;
}