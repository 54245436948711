@import "../../styles/variables";

.dropdown {
  position: relative;
  margin: auto;
  width: 100%;
  vertical-align: baseline;

  .label {
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 16px;
    color: #9098a9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    white-space: nowrap;
    transform: translateY(-26px) scale(0.75);
  }

  &.has-value .label {
    transform: translateY(-26px) scale(0.75);
  }

  // .border {
  //   position: absolute;
  //   bottom: 1px;
  //   left: 0;
  //   height: 2px;
  //   width: 100%;
  //   background: #078A34;
  //   transform: scaleX(0);
  //   transform-origin: 0 0;
  //   transition: all 0.15s ease;
  //   margin-bottom: -8px;

  //   .danger {
  //     background: $danger;
  //   }

  //   .success {
  //     background: $success;
  //   }

  //   .warnning {
  //     background: $warnning;
  //   }

  //   .info {
  //     background: $info;
  //   }
  // }

  select {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 0 8px 0px;
    margin-top: 18px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #c8ccd4;
    background: none;
    border-radius: 0;
    color: #223254;
    transition: all 0.15s ease;
    box-sizing: content-box;

    &:hover {
      background: rgba(34, 50, 84, 0.03);
    }

    &:not(:placeholder-shown) ~ .label {
      color: #5a667f;
      //transform: translateY(-26px) scale(0.75);
    }

    &:focus {
      background: none;
      outline: none;
    }

    &:focus ~ .label {
      color: $purple;
      transform: translateY(-22px) scale(0.75);
    }

    &:focus ~ .label ~ .border {
      transform: scaleX(1);
    }
  }

  .message {
    display: block;
    width: 100%;
    padding: 8px 0px;
  }

  .icon {
    position: relative;
    float: right;

    &.icon-loading {
      top: -42px;
      width: 38px;
      height: 38px;
      right: 0px;
    }

    &.icon-dropdown {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #ccc;
      margin-right: 10px;
      top: -30px;
    }

    img {
      width: 100%;
    }
  }

  &.danger {
    select {
      border-bottom-color: $danger;
    }
    .label,
    .message {
      color: $danger;
    }
  }

  &.info {
    select {
      border-bottom-color: $info;
    }
    .label,
    .message {
      color: $info;
    }
  }
}
