@import "../../styles/variables";

.navigationButtons {
  margin-top: 50px;

  .col-75{
    width: 60%;
  }
  .col-25{
    width: 40%;
  }

  button {
    display: inline-block;
    padding: 20px;
    width: 87px;
    height: 45px;
    border-radius: 4px;
    background-repeat: no-repeat;
    border: none;
    outline: none;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }
  // button:disabled {
  //   background-color: rgba(7, 138, 52, 0.6);
  //   color: $white-color;
  // }

  p {
    margin: 10px 0px;
    text-align: left;
    font-size: 0.9em;
  }

  .next:hover {
    background-color: $white-color;
    color: $main-color;
    border: 3px solid #582597;
  }

  .previous:hover {
    background-color: $main-color;
    color: $white-color;
    border: 3px solid #582597;
  }

  .previous {
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border: 3px solid $main-color;
    border-radius: 25px;
    background-color: #FFF;
    color: $main-color;
    background-position: right 3% center;
  }

  .next-icon {
    background-color: $main-color;
    color: $white-color;
    text-align: left;
    background-image: url("../../assets/images/loading-white.svg");
    background-position: right 3% center;
    background-size: 38px 38px;
  }

  .next {
    display: flex;
    width: 130px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    border: 3px solid $main-color;
    border-radius: 25px;
    background-color: $main-color;
    color: $white-color;
    // background-image: url("../../assets/images/-_.png");
    background-position: right 3% center;
  }
}

@media screen and (min-width: $width-lg) {
  .navigationButtons {
    button {
      // width: 100%;
      margin: 13px 0px;
      font-weight: 400;
      font-size: 1em;
      padding: 15px;
    }

    .next {
      background-position: 90% center;
    }

    .previous {
      background-position: 10% center;
    }
  }
}
