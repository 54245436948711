@import "../../styles/variables";

.table-registration-cond {

  table {
    border-collapse: unset !important;
    width: 100%;
    font-style: normal;    
    font-size: 20px;
    
    th {
      background: #582597;     
      color: #FFFFFF;   
      text-align: center !important; 
      font-weight: 400 !important;
      height: 51px;
      vertical-align: middle;
      // padding: 12px 0;
    }   
  
    td {
      background: #FFFFFF;
      text-align: left;
      height: 72px;
      vertical-align: middle;
      padding: 0 33px;
      font-weight: 400 !important;
    }

    td, th {
      border: 1px solid #C3C0C0;      
    }
  
    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;     
    }
    
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }
  
      td:last-child {
        border-bottom-right-radius: 8px;     
      }
    }
  } 
  
}