@import "../../styles/variables";


.choose-plan {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;  

  .plan-box {
    
  }

  .coupon-container {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    margin-bottom: 30px;
    gap: 5px;

    .coupon-text {      
      font-size: 12px;   
      font-weight: 600;   
      color: $main-color;
    } 
    
    .pointer {
      cursor: pointer;
    }

    .coupon-input {
      display: flex;
      flex-direction: column;
      .coupon-input-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .coupon-error {
        font-size: 12px;
        color: red;
        align-self: center;
        margin-left: 10px;
      }
    }
  }
  
  .form-group-plans {     
    flex-wrap: wrap;    
    display: flex;
    gap: 55px;
    justify-content: center;
    align-items: center;

    .card {
      width: 270px;
      height: 330px;
      display: flex;
      min-width: 230px;
      border-radius: 6px; 
      background-color: #F9F3FF;              
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); 

      .card-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 15px 25px;
        position: relative;

        .ticket-container {
          position: absolute;
          top: 10px;
          right: 10px;

          .ticket-box {
            position: relative;
            padding: 8px;
            background-image: url('../../assets/images/ticket_logo.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;           

            .text-coupon {
              font-size: 10px;
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              color: #fff;
            }
          }
        }

        .title {
          font-weight: 600;
          font-size: 22px;
        }
        .bronze {
          color: #FF8039;
        }
        .prata {
          color: #A8A8A8;
        }
        .ouro {
          color: #FFD600;
        }    

        .old-price {
          color: #C7C7C7;
          font-size: 12px;
          text-decoration: line-through;
          margin-top: 10px;
          font-weight: 500;
          display: flex;
          align-items: end;

          .rent {
            font-size: 11px;
            margin-right: 2px;            
          }
        }

        .box-price {
          display: flex;
          align-items: end;
          font-weight: 800;
          gap: 2px;
          margin: 5px 0 10px 0;


          .price-from {
            font-size: 10px;
            font-weight: 500;
            margin-right: 2px;
            color: #5A5A5A;
          }
  
          .real {
            font-size: 12px;
            color: #5A5A5A;
          }

          .price {
            font-size: 24px;
            color: #5A5A5A;
          }

          .month {
            font-size: 12px;
            color: #A8A8A8;
            font-weight: 600;
          }
        } 

        .unit-amount {
          font-size: 12px;
          font-weight: 500;
        }

        .sm {
          font-size: 10px;
          font-weight: 500;
          color: #092E4A;
          margin-bottom: 5px;
        }      

        .button {
          display: flex;         
          border-radius: 25px;       
          align-items: center;
          justify-content: center;
          margin-top: 10px;             
          transition: 0.2s;  
          text-transform: none;
        }  

        .button-outlined {
          border: 2px solid #582597;
          color: #582597;
          &:hover {
            cursor: pointer;
            background-color: #eeeded;
            opacity: 0.9;
          }
        }

        .button-contained {
          background-color: #582597;
          border: 2px solid #582597;
          color: #fff;
          &:hover {
            cursor: pointer;
            background-color: #3f1572;          
          }
        }

        .icon-check {
          font-size: 15px;
          color: #43a047;
        }

        .bold {
          font-weight: bold;
        }

        .list {
          width: 100%;
          padding: 0;
          margin-top: 10px;
        }

        .list-item {
          display: flex;
          align-items: center;
          padding: 2px 0 0 0;
          align-items: center;          
        }

        .text-center {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 0 2px;
        }

        .price-per-unit {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: end;
          font-size: 10px;
          font-weight: 400;
          color: #5A5A5A;
          justify-content: center;
        }
      }   
    }
    
  }    
}



