@import "../../styles/variables";

.payment {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .col {
        margin-bottom: 20px;
    }

    .form-payment-data {
        max-width: 530px;
        width: 100%;
        margin-right: 30px; // Compensar padding input

        .brands {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 50px 0 0 0;

            img {
                padding-right: 26px;
                height: 35px;
            }

            img:last-child {
                padding-right: 0;
            }

            .grayscale { filter: grayscale(100%); }
        }

        .field .label {
            top: -45px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #000000;     
        }
    
        .field input {
            border: 1px solid #582597;
            border-radius: 10px;       
            padding-left: 15px;
        }
    
        .field input:not(:placeholder-shown) ~ .label {
            transform: unset;
        }
    }

    .payment-value {
        margin-top: 14px;
        max-width: 630px;
        width: 100%;
        border-bottom: 1px solid #582597;
        padding: 15px;

        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #000000;
        }
    }
   
}