@import "../../styles/variables";

.contract {
  margin-top: 120px;

  .text-contrato {
    display: flex;
    margin-top: 24px;
    text-align: justify;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
  }

  p {
    margin-left: 5px;
  }

  .button-contrato {
    button {
      display: inline-block;
      padding: 20px;
      width: 87px;
      height: 45px;
      border-radius: 4px;
      background-repeat: no-repeat;
      border: none;
      outline: none;
      cursor: pointer;
    }
  
    button:focus {
      outline: none;
    }
    button:disabled {
      background-color: rgba(7, 138, 52, 0.6);
      color: $white-color;
    }

    .previous:hover {
      background-color: $main-color;
      color: $white-color;
      border: 3px solid #582597;
    }
  
    .previous {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      border: 3px solid $main-color;
      border-radius: 25px;
      background-color: #FFF;
      color: $main-color;
      background-position: right 3% center;
    }
  }
}

@media screen and (min-width: $width-lg) {
  .contract {
    .button-contrato {
      button {
        // width: 100%;
        margin: 13px 0px;
        font-weight: 400;
        font-size: 1em;
        padding: 15px;
      }
    
      button:focus {
        outline: none;
      }
      button:disabled {
        background-color: rgba(7, 138, 52, 0.6);
        color: $white-color;
      }
      
      .previous:hover {
        background-color: $main-color;
        color: $white-color;
        border: 3px solid #582597;
      }
    
      .previous {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        border: 3px solid $main-color;
        border-radius: 25px;
        background-color: #FFF;
        color: $main-color;
        background-position: right 3% center;
        margin-top: 30px;
      }
    }
  }
}