@import "../../styles/variables";

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox span {
  margin-right: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
}

.checkbox span:first-child {
  width: 20px;
  height: 20px;
  border: 2px solid #582597;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.checkbox.selected span:first-child{
  background-color: #582597;
}

.checkbox-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: #707070;    
}

.label-checkbox-component {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: #707070;
}

.items-checkbox-component {
  display: flex;
  flex-direction: row;      
}
