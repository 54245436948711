@import "../../styles/variables";

.condominiumSpecifications {    
    margin-bottom: 80px;

    .itemCard {
        margin-top: auto;
        padding-bottom: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .itemCard span:last-child {
        font-weight: 500;
        font-size: 15px;
    }

    .house {
        background: url("../../assets/images/house.png") center 42px no-repeat;
    }

    .one-block {
        background: url("../../assets/images/one-block.png") center 42px no-repeat;
    }

    .multiples-blocks {
        background: url("../../assets/images/multiples-blocks.png") center 42px no-repeat;
    }

    .house:hover,
    .house.selected {
        background: url("../../assets/images/house-selected.png") center 42px no-repeat, #582597;
    }

    .one-block:hover,
    .one-block.selected {
        background: url("../../assets/images/one-block-selected.png") center 42px no-repeat, #582597;
    }

    .multiples-blocks:hover,
    .multiples-blocks.selected {
        background: url("../../assets/images/multiples-blocks-selected.png") center 42px no-repeat, #582597;
    }        
}
