@import "../../styles/variables";

.btn-registration-cond {
    display: flex;
    justify-content: center;

    button {
        margin: 55px auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 15px;
        // width: 200px;
        // height: 46px;
        background: #672CB0;
        border: 1px solid #672CB0;
        border-radius: 7px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;

        cursor: pointer;
    }
}