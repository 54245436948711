@import "../../../styles/variables";

.condominiumSpec_condominium_blocks {
  .cards-cond-blocks {
    border: 2px solid #582597 !important;
    border-radius: 10px !important;
    font-size: 32px;
    margin-top: 3px;
    width: 50px;
    max-height: 70px;
    margin-right: 2px;
  }
}
.condominiumSpec_condominium_checkNameOfUnits {
  display: flex;
  margin: 12px 0;
  align-items: end;
}

.condominiumSpec_condominium_checkNumberOfUnits {
  display: flex;
  margin: 12px 0;
  align-items: end;
}

@media screen and (max-width: 750px){
  .cards-cond-blocks{
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;   
    border: 1px solid #582597 !important;   
  }
}
