@import "../../styles/variables";

.administrativeHead {
  .color-red {
    color: #FF0000 !important;
  }
  .color-green {
    color: #44B727 !important;
  }
  .pass-validation {
    margin: 12px 0 0 22px;
    color: #FF0000;    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 150%;

    ul {
        list-style: disc;
    }
  }
}
